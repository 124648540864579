import React from "react";
import "./AllProducts.css";
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const STOREDATA = gql`
    query Store{
        stores {
          	id
          	productName
          	price
          	coverImage {
              url
          	  id
          	}
        }
    }
`;


function Design(props){
    return(
        <NavLink key={props.id} to={`/store/${props.id}`} className="product-card">
                <img className="image" src={props.coverImage.url} alt="post" />
                <div className="Heading">
                    <p className="title">{props.productName}</p>
                </div>
                <p className="price">₹{props.price}</p>                
        </NavLink>
    )
}



export default function AllProducts(){

    const { loading, error, data } = useQuery(STOREDATA);
    
    if(loading) return <div className="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    // const all_products = data.stores.map((data) => {
    const all_products = data.stores.map(( data ) => {
        return(
            <Design
                key = { data.id }
                id = { data.id }
                productName = { data.productName }
                price = { data.price }
                coverImage = { data.coverImage }
            />
        )
    })
    return(
        <>
            <div className="all-product-area">
                { all_products }
            </div>
        </>
    )
}