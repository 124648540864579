import React from "react";
// import { useNavigate } from 'react-router-dom';
import './Home.css';
import './index.css';
import Carousel from "./Carousel";

function Profile(){
    return(
        <div className="profile_content">
            <div className="profile">
                <div className="profile_image">                    
                    <img src="./images/home_images/selfie.jpg" alt="Selfie" />
                </div>
                <div className="profile_details">
                    <div className="status">
                        {/* <p>Current Status : <mark className="mark2">Available</mark></p> */}
                        <p className="mark2">#Improvising</p>
                    </div>
                    <p className="intro"> 
                        <div className="intro-name">
                            Hi <img src="https://github.com/TheDudeThatCode/TheDudeThatCode/blob/master/Assets/Hi.gif?raw=true" 
                            alt="Hand Emoji Wave" style={{width:'25px'}}/>
                            , I'm <mark className="highlight1">Hari Vishnu</mark>.
                        </div>
                        
                        <div className="intro-content"> 
                        {/* <br /> */}
                            I am a <mark className="highlight2">Full stack web developer</mark>.
                            I have 2+ years of experience in web development. I solve business 
                            problems through human-centered design, with a focus 
                            on <mark className="highlight2">simplicity</mark>, <mark className="highlight2">sophistication</mark>, and <mark className="highlight2">efficiency.</mark> Feel free to explore my portfolio.
                        </div>
                    </p>
                </div>
                {/* <div className="arrow">
                    <i className="fi fi-sr-arrow-circle-down"></i>
                </div> */}
            </div>
        </div>
    )
}


function TechStack(){
    return(
        <div className="tech_stack">
            <p className="title">Tech Stack</p>
            <div className="list">
                <div className="html_sec area">
                    <img src="./images/home_images/html.png" alt="Html-logo" />
                    <p className="logo_title">Html</p>
                </div>
                <div className="css_sec area">
                    <img src="./images/home_images/css-3.png" alt="css-logo" />
                    <p className="logo_title">Css</p>
                </div>
                <div className="js_sec area">
                    <img src="./images/home_images/js.png" alt="js-logo" />
                    <p className="logo_title">Java Script</p>
                </div>
                <div className="react_sec area">
                    <img src="./images/home_images/react.png" alt="react-logo" />
                    <p className="logo_title">React</p>
                </div>
                <div className="python_sec area">
                    <img src="./images/home_images/python.png" alt="python-logo" />
                    <p className="logo_title">Python</p>
                </div>
                <div className="php_sec area">
                    <img src="./images/home_images/php.png" alt="php-logo" />
                    <p className="logo_title">Php</p>
                </div>
                <div className="git_sec area">
                    <img src="./images/home_images/git.png" alt="git-logo" />
                    <p className="logo_title">Git</p>
                </div>
                <div className="mysql_sec area">
                    <img src="./images/home_images/mysql.png" alt="mysql-logo" />
                    <p className="logo_title">My Sql</p>
                </div>
            </div>
        </div>
    )
}


function Contacts(){
    const github_link = () => {
        window.location.href = "https://github.com/Im-harivishnu";
    };

    // const navigate = useNavigate();
    
    const linkedin_link = () => {
        window.location.href = "https://www.linkedin.com/in/im-harivishnu";
    };

    return(
        <div className="contacts_content">
            <div className="contacts">
                <div className="ct-title">My Contacts</div>
                <div className="stack">
                    <div className="email sep_cnct">
                        <p>harivishnu0209@gmail.com</p>
                    </div>
                    <div className="instagram sep_cnct">                     
                        <p>im_harivishnu</p>
                    </div>
                    <div className="threads sep_cnct">
                        <img src="./images/home_images/threads-logo.png" alt="threads-logo" />
                        <p>im_harivishnu</p>
                    </div>
                    <div className="twitter sep_cnct">
                        <img src="./images/home_images/twitter.png" alt="twitter-logo" />
                        <p>Im_Harivishnu</p>
                    </div>
                    <div className="github sep_cnct" onClick={github_link}>
                        {/* <Redirect to='https://github.com/Im-harivishnu' /> */}
                        <img src="./images/home_images/github.png" alt="github-logo" />
                    </div>
                    <div className="linkedin sep_cnct"  onClick={linkedin_link}>
                        <img src="./images/home_images/linkedin.png" alt="linkedin-logo" />
                        <p>LinkedIn</p>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default function Home(){
    return(
        <>
            <Profile />
            <TechStack />
            <Contacts />
            {/* <Carousel /> */}
        </>
    )
}