import React from "react";
import { useQuery, gql } from '@apollo/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Post from "./Post";
import { shoes } from "./mongoConnection";

const DATA = gql`
    query MyQuery {
        postsConnection {
            edges {
                node {
                    id
                    createdAt
                    slug
                    title
                    excerpt
                    coverImage {
                        url
                    }
                    categories {
                        name
                        slug
                    }
                    content {
                        html
                        json
                    }
                    author {
                        bio
                        name
                        id
                        image {
                            url
                        }
                    }
                    photoCollection {
                      id
                      fileName
                      url
                    }
                }
            }
        }
    }
`;


function BlogPage(){

    const { loading, error, data } = useQuery(DATA);
    console.log(data);

    if(loading) return 'Loading..!';

    if(error) return `Error. ${error.message}`;


    return data.postsConnection.edges.map(( track ) => (
        <>
            <Routes>
                <Route exact path={`/blog/post/:${track.node.id}`} Component={Post} />                
            </Routes>
            <div key={track.node.id}>
                {/* <p>{track.node.id}</p>
                <p>{track.node.slug}</p>
                <p>{track.node.title}</p>
                <ul>
                    <li>{track.node.author.id}</li>
                    <li>{track.node.author.bio}</li>
                    <li>{track.node.author.name}</li>
                    <li>{track.node.author.image.url}</li>
                </ul>
                <p>{track.node.createdAt}</p>
                <p>{track.node.excerpt}</p>
                <p>{track.node.coverImage.url}</p>
                <p>{track.node.categories.name}</p>
                <p>{track.node.categories.slug}</p>
                <div dangerouslySetInnerHTML={{__html:track.node.content.html}} div/> */}
            </div>
        </>
    ));
};

export default BlogPage;