import React, { useEffect } from "react";
import { useState } from 'react';
import "./Product.css";
import { useQuery, gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import { RichText } from "@graphcms/rich-text-react-renderer";


import Images from "./Images";

const STOREDATA = gql`
    query Store{
        stores {
          	id
          	productName
          	price
          	coverImage {
              url
          	  id
          	}
            image {
                id
                url
                original: url
                thumbnail: url
                fileName
            }
          	productsImage{
                id
                images{
                    html
                    raw
                }
            }
        }
    }
`;



export default function Product(){
    // let [value, setValue] = useState(0);

    let get_id = useParams();
    
    const { loading, error, data } = useQuery(STOREDATA);
    get_id = Object.values(get_id).join();
    console.log(data);
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    return data.stores.filter(data => data.id === get_id).map(( track ) => (
        <>
            <div className="product-area">
                <Images />

                <div key={track.id} className="product-details">
                    <p className="pdt-name">{ track.productName }</p>
                    <p className="pdt-price">₹{ track.price }</p>
                </div>
            </div>
            
        </>
    ));
};