import React from "react";
import "./Images.css";
import { useParams } from "react-router-dom";
import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';

const STOREDATA = gql`
    query Store{
        stores {
          	id
          	productName
          	price
          	coverImage {
              url
          	  id
          	}
            image {
                id
                url
                fileName
            }
          	productsImage{
                id
                images{
                    html
                    raw
                }
            }
        }
    }
`;

export default function Images(){
    let [value, setValue] = useState(0);

    let get_id = useParams();
    
    const { loading, error, data } = useQuery(STOREDATA);
    get_id = Object.values(get_id).join();
    // console.log(data);
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    return data.stores.filter(data => data.id === get_id).map(( track ) => (
        <>
            <div className="image-area"> 

                <img className="image-view" src={track.image[value].url} />

                <div className="image-controls">
                    <button 
                        className="prev-btn"
                        onClick={() => setValue(a => a-1)}
                        disabled={value===0 ? true : ""}
                        ><i class="fi fi-rr-caret-left"></i>
                    </button>
                    
                    <p className="image-number">{value+1} / {track.image.length}</p>

                    <button 
                        className="next-btn"
                        onClick={() => setValue(a => a+1)}
                        disabled={value===track.image.length-1 ? true : ""}
                        ><i class="fi fi-rr-caret-right"></i>
                    </button>
                </div>
            </div>
        </>
    ));
};