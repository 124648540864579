import React, { useState } from "react";
import "./Login.css";
import axios from "axios";

function showLogin(){
    document.getElementById("loginpage").style.marginLeft = "0";
}

function showSignup(){
    document.getElementById("loginpage").style.marginLeft = "-400px";
}

export default function Login(){

    const [input, setInput] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInput(values => ({...values, [name]:value }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post("https://localhost:3000/Login", input);
        console.log(input);
    }

    return(
        <div className="body">
            <div className="toggle-page">

                <div className="switch-field">
                    <button onClick={showLogin} className="login-btn">Login</button>
                    <button onClick={showSignup} className="signup-btn">SignUp</button>
                </div>

                <div className="toggle-area">
                    <div id="loginpage" className="login-page">
                        <div className="login-area">
                            <p className="login-label">Login</p>
                            <div class="input-wrapper">
                                <label className="input-label" for="first">Username</label>
                                <input type="text" />
                            </div>
                            <div class="input-wrapper">
                                <label className="input-label" for="second">Password</label>
                                <input type="password" />
                            </div>
                            {/* <input type="submit" defaultValue="Login">Login</input> */}
                            <button className="login-button" type="submit">Login</button>
                        </div>
                    </div>
                    
                    <div className="signup-page">
                        <form className="signup-area" onSubmit={handleSubmit}>
                            <p className="login-label">Sign Up</p>
                            <div class="input-wrapper">
                                <label className="input-label" for="first">Username</label>
                                <input 
                                    type="text"
                                    name="username"
                                    onChange={handleChange}
                                />
                            </div>
                            <div class="input-wrapper">
                                <label className="input-label" for="first">E mail</label>
                                <input 
                                    type="text"
                                    name="email"
                                    onChange={handleChange}
                                />
                            </div>
                            <div class="input-wrapper">
                                <label className="input-label" for="first">Password</label>
                                <input 
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                />
                            </div>
                            <div class="input-wrapper">
                                <label className="input-label" for="first">Re-enter Password</label>
                                <input 
                                    type="password"
                                    name="cpassword"
                                    onChange={handleChange}
                                />
                            </div>
                            <button class="clkbtn">Signup</button>
                        </form>
                    </div>
                </div>           
            </div>
        </div>
    )
}