import * as React from "react";
import * as ReactDOM from "react-dom";
import { ApolloProvider } from '@apollo/client';

import client from "./Blog/apolloClient";
import routing from "./routing";




    
ReactDOM.render(
    <ApolloProvider client={client}>
        { routing }
    </ApolloProvider>, document.getElementById('root'));