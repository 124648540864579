import React from "react";

import "./AdjacentPosts.css";
import { useParams } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';


const DATA = gql`
    query MyQuery {
        postsConnection {
            edges {
                node {
                    id
                    slug
                    title
                    postNumber
                }
            }
        }
    }
`;

export function PreviousPost(props){
    
    const { loading, error, data } = useQuery(DATA);
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    console.log("Prev Post", props.postno);

    return data.postsConnection.edges.filter(data => data.node.postNumber === props.postno).map(( track ) => (
        <NavLink 
            key={track.node.id} 
            to={`/blog/${track.node.id}`}  
            className="prev-post"
            onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }}
        >
            <p className="post-title">Previous</p>
            <p className="post-name">{track.node.title}</p>
        </NavLink>
    ));
}


export function NextPost(props){
    
    const { loading, error, data } = useQuery(DATA);
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    console.log("Next Post", props.postno);

    return data.postsConnection.edges.filter(data => data.node.postNumber === props.postno).map(( track ) => (
        <NavLink 
            key={track.node.id} 
            to={`/blog/${track.node.id}`} 
            className="next-post" 
            onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }}
        >
            <p className="post-title">Next</p>
            <p className="post-name">{track.node.title}</p>
        </NavLink>
    ));
}


export default function AdjacentPosts(){

    let get_id = useParams();
    
    const { loading, error, data } = useQuery(DATA);

    get_id = Object.values(get_id).join();
    console.log("Current Post Id:", get_id)
    
    if(loading) return <div class="loader"></div>;
    
    if(error) return `Error. ${error.message}`;

    
    return data.postsConnection.edges.filter(data => data.node.id === get_id).map(( track ) => (
        <div className="adj-post-area">
            <PreviousPost postno={track.node.postNumber-1} />
            <NextPost postno={track.node.postNumber+1} />
        </div>
    ));
}