import React from "react";
import Header from "./Header.js";
import Home from "./Home.js";
import Footer from "./footer.js";




export default function App(){
    return(
        <>
            <Header />
            <Home />
            <Footer />
        </>
    )
}